import React from "react"
import Container, { ContainerProps } from "./Container"
import BottomLeft from "./dot-patterns/BottomLeft"
import TopRight from "./dot-patterns/TopRight"

interface Props extends ContainerProps {
  children: JSX.Element
  dotPosition?: "top-right" | "bottom-left"
}

export default ({
  children,
  backgroundClassName,
  dotPosition,
}: Props): JSX.Element => {
  let SvgElement: () => JSX.Element

  if (dotPosition === "top-right") {
    SvgElement = TopRight
  } else {
    SvgElement = BottomLeft
  }

  return (
    <Container backgroundClassName={backgroundClassName}>
      <SvgElement />
      {children}
    </Container>
  )
}
