import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import BasicList from "../components/BasicList"
import Container from "../components/Container"
import DotSection from "../components/DotSection"
import Hero from "../components/Hero"
import HeroLead from "../components/HeroLead"
import HeroTitle from "../components/HeroTitle"
import Layout from "../components/Layout"
import SEO from "../components/SEO/SEO"
import SubTitle from "../components/SubTitle"
import Text from "../components/Text"
import Title from "../components/Title"
import links from "../content/links"
import metaImg from "../images/luottokorttipuhelin.svg"

export default (): JSX.Element => {
  return (
    <Layout>
      <SEO
        title="Kotisivut yritykselle"
        description="Teemme yritykselle kotisivut nopeasti ja laadukkaasti. Meiltä saat myös sivuillesi hakukoneoptimoinnit, digitaalisen markkinoinnin ja laajan asiantuntemuksen."
        pageImage={metaImg}
        pageUrl={links.website}
      />

      <section id="hero">
        <Hero>
          <HeroTitle>
            <h1>
              <span className="block">Kotisivut</span>
              <span className="block text-indigo-400">yritykselle</span>
            </h1>
          </HeroTitle>
          <HeroLead>
            Tarjoamme sinulle ammattimaiset kotisivut, jotta saavutat
            yrityksellesi uskottavuutta ja tulet löytymään hakutuloksista
            järkevillä ratkaisuilla ja kuluilla!
          </HeroLead>
        </Hero>
      </section>

      <section id="homepage">
        <DotSection backgroundClassName="bg-white" dotPosition="top-right">
          <div className="relative lg:grid md:grid-cols-2 md:gap-x-8">
            <div>
              <Text>
                Nykyään kotisivujen tärkeys on noussut huomattavasti siitä mitä
                se on ollut aikaisemmin. Ihmiset etsivät tietoja kaikesta
                internetin avulla – joten jos sinulla ei ole kotisivuja jäät
                helposti huomaamatta. Jos sinulla on jo valmiina kotisivut,
                mutta ne ovat huonosti toteutettu ja suunniteltu, niin hyvin
                mahdollisesti potentiaalinen asiakas vaihtaa kilpailijan
                sivuille.
              </Text>
              <Text>
                Vaikka huonolaatuiset kotisivut ovat silti paremmat kuin ei
                mitään, tulisi niihin panostaa.
              </Text>
              <Text>
                Kotisivujen tärkein tehtävä on kasvattaa yrityksen myyntiä tai
                tunnettavuutta, riippuen siitä mitä kotisivuilla haetaan.
                Kotisivujen täytyy pystyä todistamaan sinun ammattiosaaminen ja
                sen lisäksi niiden on tehtävä ostaminen / tiedon etsiminen
                asiakkaalle helpoksi.
              </Text>
              <Text>
                Teemme sinulle toimivat ja silmää miellyttävät kotisivut, jotka
                auttavat sinua tavoittamaan uusia asiakkaita ja saamaan
                yrityksesi kasvamaan.
              </Text>
              <Text>
                Tarjoamme sinulle ammattimaiset kotisivut, jotta saavutat
                yrityksellesi uskottavuutta ja tulet löytymään hakutuloksista
                järkevillä ratkaisuilla ja kuluilla!
              </Text>
            </div>
            <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 mt-6 lg:mt-0">
              <StaticImage
                src="../images/2-left-trans-color.svg"
                alt="Applikaatio layoutit"
              />
            </div>
          </div>
        </DotSection>

        <section id="what-is-homepage" className="bg-gray-800">
          <Container>
            <div>
              <Title tag="h2" textColor="text-white">
                Mitä ovat kotisivut?
              </Title>
              <Text textColor="text-white">
                Kotisivut ovat verkkosivu, mikä on suunniteltu sinun tarpeittesi
                mukaan. Se on tärkeä tiedotus- ja markkinointikanava ja se
                saattaa olla myös monen asiakkaan ensimmäinen kosketus
                yritykseesi. Kotisivuihin tarvitaan asiakasta puhuttelevaa
                sisältöä ja edustava ulkoasu, mikä auttaa sinua menestymään,
                sekä kotisivut ovat yritykselle merkittävä osa yrityksesi
                julkisivua, joka palvelee asiakkaitasi päivin ja öin.
              </Text>
              <Text textColor="text-white">
                Hyvin toteutetut ja suunnitellut kotisivut ovat verkkosivustosi
                asiakaspalvelija ja myyjä. Ne etsivät sinulle uusia asiakkaita,
                uusia kontakteja sekä uusia projekteja. Kotisivut tulee
                suunnitella asiakasta varten. Vaikka sinusta jokin asia olisi
                itsestäänselvyys niin tuleva asiakas ei välttämättä tiedä juuri
                sinun ammattisanastoa, vaan se tulisi kertoa selkeästi heille.
                Kotisivut ovat sinun asiakkaillesi, ei sinulle tai yrityksellesi
                – joten ota se huomioon niitä suunnitellessa.
              </Text>
              <div className="mt-10">
                <Title tag="h3" textColor="text-white">
                  Voit tehdä ainoastaan kerran ensivaikutelman, joten tee se
                  huolella ja hyvin
                </Title>
              </div>
              <Text textColor="text-white">
                Mikä olisi sen parempaa kuin tyytyväinen asiakas, joka kehuu
                saamaansa palvelua ystävilleen? Hyvä sisältö auttaa sinua
                palvelemaan uusia kuin vanhoja asiakkaita.
              </Text>
              <Text textColor="text-white">
                Kotisivujen tarkoitus on kehittää yritystäsi. Monesti kotisivut
                jäävät tekemättä, tai niihin ei panosteta mikä taas antaa huonon
                vaikutelman yrityksestäsi, sillä nykyään kaikki löytyy
                internetistä. Oikein suunniteltuna ja toteutettuna kotisivut
                ovat tehokas markkinoinnin ja myynnin työkalu ja jonka avulla
                sinun ei välttämättä tarvitse maksaa siitä, että tulet näkymään
                hakutuloksissa.
              </Text>
              <Text textColor="text-white">
                Kotisivuissa on huomioitava sen helppokäyttöisyys. Ihmiset ovat
                tottuneet, että tietyt elementit löytyvät tietyiltä paikoilta ja
                jos siirrät niitä paljon ja haluat luoda aivan uudenlaisen
                sivuston, on navigointi hankalaa ja hidasta. Siihen voi tottua,
                mutta saatat menettää kymmeniä tai satoja asiakkaita sen takia.
                Siksi on kiinnitettävä huomiota siihen, että sivuilla navigointi
                on yksinkertaista ja helppoa.
              </Text>
            </div>
          </Container>
        </section>

        <section id="responsive">
          <DotSection dotPosition="top-right">
            <div className="relative lg:grid lg:grid-cols-2 lg:gap-x-8">
              <div>
                <Title tag="h2">Responsiiviset kotisivut</Title>
                <Text>
                  Kotisivujen on pystyttävä tarjoamaan käyttäjilleen hyvä
                  kokemuksen alustalla kuin alustalla. Sivujen on pystyttävä
                  toimimaan responsiivisesti niin pöytätietokoneella,
                  tableteilla kuin älypuhelimilla.
                </Text>
                <Text>
                  Tuotamme jokaisesta kotisivusta responsiivisen, jotta se olisi
                  paras mahdollinen kokemus asiakkaallesi alustasta riippumatta.
                </Text>
                <Text>
                  Mitä responsiivisuus tarkoittaa? Se on sitä, että sama sivusto
                  mukautuu eri laitteille sopivaksi. Hyvänä esimerkkinä on se,
                  että pöytätietokoneella kuvat ja teksti on suurempia kuin taas
                  kännykällä. Eli sivut skaalautuvat näytön koon mukaan.
                </Text>
                <Text>
                  On tärkeää, että sivut, ei pelkästään kotisivut vaan kaikki
                  internetsivut olisivat responsiiviset. Mikään ei ole sen
                  ärsyttävämpää kuin etsiä kännykällä tietoja yrityksestä /
                  paikasta ja yrityksen sivuja ei ole tehty
                  mobiiliystävälliseksi. Kuvat eivät mahdu näytölle, teksti on
                  aivan liian suurta ja kokonaisuutena sinun on hankala käyttää
                  kännykällä sivuja.
                </Text>
                <Text>
                  Responsiivisuuden lisäksi teemme kotisivuista laadukkaat ja
                  hakukoneystävälliset.
                </Text>
              </div>
              <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 mt-6 lg:mt-0">
                <StaticImage
                  src="../images/1-right-trans-color.svg"
                  alt="Profiilikuva hehkulampun päällä"
                />
              </div>
            </div>
          </DotSection>
        </section>

        <section id="planning">
          <Container>
            <Title tag="h2">Kotisivujen suunnitteluprosessi</Title>
            <Text>
              Alla olevista kohdista voit miettiä jo etukäteen sitä, mitä tulet
              tarvitsemaan juuri sinun kotisivuillasi.
            </Text>
            <div className="grid md:grid-cols-2 md:space-x-5">
              <div className="mt-10">
                <SubTitle tag="p">Ulkoasun tyyli:</SubTitle>
                <BasicList>
                  <li>
                    <Text>Minulla on jo kotisivuille suunniteltu ulkoasu</Text>
                  </li>
                  <li>
                    <Text>Tarvitsen yksinkertaisen ulkoasun</Text>
                  </li>
                  <li>
                    <Text>Tarvitsen kustomoidun ulkoasun</Text>
                  </li>
                  <li>
                    <Text>
                      Tarvitsen ainutlaatuisen – räätälöidyn yksilöllisen
                      ulkoasun
                    </Text>
                  </li>
                </BasicList>
              </div>
              <div className="mt-10">
                <SubTitle tag="p">Logo:</SubTitle>
                <BasicList>
                  <li>
                    <Text>Tarvitsen logon</Text>
                  </li>
                  <li>
                    <Text>Minulla on jo logo</Text>
                  </li>
                  <li>
                    <Text>Minulla on jo logo, mutta sitä pitää työstää</Text>
                  </li>
                </BasicList>
              </div>
              <div className="mt-10">
                <SubTitle tag="p">Verkkokauppa:</SubTitle>
                <BasicList>
                  <li>
                    <Text>En tarvitse verkkokauppaa</Text>
                  </li>
                  <li>
                    <Text>Tarvitsen yksinkertaisen verkkokaupan</Text>
                  </li>
                  <li>
                    <Text>Tarvitsen monipuolisen verkkokaupan</Text>
                  </li>
                </BasicList>
              </div>

              <div className="mt-10">
                <SubTitle tag="p">Sisältö:</SubTitle>
                <BasicList>
                  <li>
                    <Text>Tuotan sisällön itse</Text>
                  </li>
                  <li>
                    <Text>Haluan, että minulle tuotetaan sisältö</Text>
                  </li>
                </BasicList>
              </div>
            </div>
          </Container>
        </section>

        <section id="progressing" className="bg-gray-800">
          <Container>
            <div className="text-center">
              <Title tag="h2" textColor="text-white">
                Miten kotisivujen rakennusprosessi etenee?
              </Title>
            </div>
          </Container>
        </section>

        <section id="planning-and-order">
          <Container>
            <Title tag="h3">Suunnittelu & Tilaus</Title>
            <BasicList>
              <li>
                <Text>
                  Kotisivujen suunnittelu aloitetaan vaihtamalla ideoita ja
                  ajatuksia verkkosivuston tekijän ja tilaajan kesken. Tilaaja
                  kertoo toiveet ja sen, mitä haluaisi sivuilta sekä mihin
                  tarkoitukseen sivut ovat. Tämän avulla saamme paremman
                  käsityksen siitä, mitä projektilta halutaan, mikä on päämäärä
                  ja haluttu lopputulos.
                </Text>
              </li>
              <li>
                <Text>
                  Keskustelemme siitä, onko sinulla jo valmiina sisältö,
                  täytyykö sitä muokata vai suunnittelemmeko me sisällön
                  sinulle. Onko sinulla valmis suunnitelma siitä miltä sivujen
                  tulisi näyttää vai onko sinulla muiden verkkosivuista kuvia.
                  Kuvia siis siitä minkä tyylistä sivustoa haet ja mistä pidät.
                  Millaisia ovat kotisivuilla käytettävät kuvat ja logot, onko
                  niitä vai tuotammeko me ne sinulle.
                </Text>
              </li>
              <li>
                <Text>Tarvitsetko verkkokaupan vai et.</Text>
              </li>
              <li>
                <Text>
                  Verkko-osoitteen ja webhotellin hankkiminen. Entä yrityksen
                  sähköpostitilit? Luommeko sinulle yrityksen
                  sähköpostiosoitteet verkko-osoitetta ja palvelinta käyttäen
                  eli @yrityksesi.fi-pääte, mikä taas antaa ammattimaisemman
                  kuvan yrityksestäsi kuin käyttämällä ilmaisia @gmail.com tai
                  @hotmail.com-päätteitä.
                </Text>
              </li>
            </BasicList>
          </Container>
        </section>

        <section id="planning-and-draft" className="bg-gray-800">
          <Container>
            <Title tag="h3" textColor="text-white">
              Suunnittelu & Luonnos
            </Title>
            <BasicList>
              <li>
                <Text textColor="text-white">
                  Teemme raakavedoksen, eli luonnoksen siitä, miltä kotisivujen
                  tulisi näyttää. Tähän mennessä tekstien ei tarvitse olla vielä
                  valmiina eikä kuvia päätetty, mutta tulet näkemään osviittaa
                  ja värimaailmaa siitä miltä sivut tulevat näyttämään.
                  Lähetämme nämä sinulle ja pääset itse kommentoimaan sivuja
                  sekä kertomaan mielipiteesi.
                </Text>
              </li>
              <li>
                <Text textColor="text-white">
                  Tämän jälkeen teemme tarvittavat muutokset ja sen jälkeen
                  alamme lisäämään tekstejä ja kuvia paikoilleen. Saatuamme ne
                  valmiiksi, lähetämme tuloksen uudelleen sinulle nähtäväksi ja
                  pääset kertomaan mielipiteesi ja teemme tarvittavat muutokset
                  ja korjaukset.
                </Text>
              </li>
            </BasicList>
          </Container>
        </section>

        <section id="finishing-and-installation">
          <Container>
            <Title tag="h3">Viimeistely & Kotisivujen asennus</Title>
            <BasicList>
              <li>
                <Text>
                  Asennamme sivun oikeaan osoitteeseen ja teemme viimeistelyt ja
                  hienosäädöt.
                </Text>
              </li>
            </BasicList>
          </Container>
        </section>

        <section id="publishing-and-maintenance" className="bg-gray-800">
          <Container>
            <div>
              <Title tag="h3" textColor="text-white">
                Julkaisu & Ylläpito
              </Title>
              <BasicList>
                <li>
                  <Text textColor="text-white">
                    Sivut julkaistaan ja annamme sinulle tarvittavan
                    ohjeistuksen sivujen ylläpitämiseen.
                  </Text>
                </li>
                <li>
                  <Text textColor="text-white">
                    Käymme huolellisesti läpi ja varmistamme, että kotisivut
                    toimivat eri laitteilla ja skaalautuvat niiden näyttöjen
                    mukaan. Varmistamme, että jokainen linkki ja mahdolliset
                    lisätoiminnot, kuten yhteydenottolomakkeet, verkkokaupan
                    tilaukset jne. toimivat niin kuin niiden pitääkin.
                  </Text>
                </li>
                <li>
                  <Text textColor="text-white">
                    Nyt sinun on aika vain nauttia uusista sivuista ja niiden
                    tuomista uusista asiakkaista!
                  </Text>
                </li>
              </BasicList>
            </div>
          </Container>
        </section>

        <section id="technical-parts">
          <DotSection dotPosition="top-right">
            <div>
              <Title tag="h2">Teknisten osien tärkeys</Title>
              <Text>
                Kotisivuissa kuin muissakin verkkosivuissa on huomioitava, että
                koodin tulee olla pakattu. Eli niin sanotusti koodia täytyy
                pienentää ja sieltä tulee poistaa ylimääräiset enterit, tyhjät
                välit, pilkut ja pisteet mitä ei tarvita, eli kaikki mikä vie
                ylimääräistä tilaa.
              </Text>
              <Text>
                On myös otettava huomioon, että mobiililaitteilla saa huonommat
                pisteet mikäli sivu latautuu hitaasti. Nykyään kun suurin osa
                ihmisistä käyttää älypuhelimia, on siihen kiinnitettävä
                erityisesti huomiota.
              </Text>
              <Text>
                Siihen mitkä vaikuttavat sivun latautumisnopeuteen on monia
                objekteja. Esimerkiksi jos kotisivuille laitettavat kuvat ovat
                liian suuria ja kuvia on paljon, sivujen latausnopeus hidastuu
                huomattavasti. Kuvat tulee editoida juuri oikean kokoisiksi
                huomioiden sille tarkoitetun paikan internetsivuilla.
              </Text>
              <Text>
                Googlelta löytyy hyvä latausnopeuksien seuraamiseen tarkoitettu
                sivusto{" "}
                <Link to="https://testmysite.withgoogle.com/intl/fi-fi">
                  https://testmysite.withgoogle.com/intl/fi-fi
                </Link>
                .
              </Text>
              <Text>
                Mobiililaitteilla on myös kiinnitettävä huomiota siihen etteivät
                objektit ole liian pieniä. Jos teksti on liian pientä, liian
                tiheää ja sinulla on linkkejä sivuilla, on erityisesti
                kiinnitettävä huomiota siihen, että tekstin linkkien tulee olla
                painettavissa. Jos linkit ovat liian lähellää toisiaan, saatat
                vahingossa painaa viereistä linkkiä tarkoittamatta.
              </Text>
              <Text>
                Kaiken yllämainitun lisäksi, myös cachen käyttö on tärkeää
                tiedonsiirron vähentämiseksi. Cache on välimuisti eli nopea
                muisti, jonka tehtävä on nopeuttaa tietokoneen toimintaa.
              </Text>
            </div>
          </DotSection>
        </section>

        <section id="tips" className="bg-gray-800">
          <Container>
            <div>
              <Title tag="h2" textColor="text-white">
                Vinkkejä sisältöön
              </Title>
              <div className="mt-10">
                <SubTitle tag="h3">Sisällöntuotanto</SubTitle>
                <Text textColor="text-white">
                  Kun kotisivuja aletaan rakentamaan ja päätät itse kirjoittaa
                  tekstit, on hyvä muistaa ottaa huomioon Googlen hakukoneet,
                  miten teksti on kirjoitettu, mitä hakusanoja ja kuinka paljon
                  niitä on käytetty. Hyvä aloituspiste on kirjoittaa ylös
                  hakusanoja millä kilpailijasi löytyvät Googlesta.
                </Text>
                <Text textColor="text-white">
                  Hakukoneoptimoinnissa on huomioitava esimerkiksi kotisivujen
                  sisältö sekä sen otsikot. Lisäksi on huomioitava hakusanojen
                  oikeaoppinen sijoittelu kotisivuille. On mietittävä,
                  halutaanko luoda jokaiselle tärkeälle hakusanalle oma, niin
                  sanottu alasivu sekä siihen liittyvä vahva ja oleellinen
                  sisältö, mikä auttaa hakukoneoptimoinnissa.
                </Text>
                <Text textColor="text-white">
                  Tekstiä tulisi olla ja liian lyhyt teksti ei auta sinua
                  sijoittumaan hyvin hakutuloksissa ja liian puutteellinen
                  teksti taas ei ole hyvä yrityksesi kannalta. Sisällössä on
                  huomioitava rakenne, logiikka, lauserakenteet ja ylipäätänsä
                  kielioppi. Riippuen siitä, kuka on kohderyhmäsi, ovatko he
                  iäkkäitä ihmisiä, keski-ikäisiä, aikuisia, nuoria-aikuisia,
                  teinejä, lapsia on kielen rakenteeseen hyvä kiinnittää
                  huomiota ja siihen miten kieltä käytetään.
                </Text>
                <Text textColor="text-white">
                  On myös hyvä miettiä sisällön suhteen sitä, mitä ja miten
                  yrityksesi toimintaa voitaisiin esitellä mahdollisimman
                  tehokkaasti. Mikä on se sinun juttusi? Blogi? Portfolio
                  toteutuneista töistäsi? Käynnissä oleva projekti? Mikä on
                  sinun mielestäsi arvokasta yrityksellesi?
                </Text>
                <Text textColor="text-white">
                  Silti tekstien suhteen paras vaihtoehto olisi saada
                  ammattikirjoittaja kirjoittamaan ja auttamaan sinua
                  sisällöntuotannossa, sillä sisältö on tärkein asia
                  kotisivuillasi. Ammattikirjoittajan palvelut tottakai maksavat
                  aina vähän enemmän, mutta heidän tuottamansa teksti on yleensä
                  laadukkaampaa ja sillä saattaa olla parempi vaikutus
                  kotisivujesi ja yrityksesi menestykseen.
                </Text>
              </div>
              <div className="mt-10">
                <SubTitle tag="h3">Kuvamateriaali</SubTitle>
                <Text textColor="text-white">
                  Olet varmaan kuullut sanonnan: “kuva kertoo enemmän kuin tuhat
                  sanaa”. Tämä pitää paikkaansa ja siksi on erityisen tärkeää
                  kiinnittää huomiota kuviin mitä kotisivuille laitetaan. Kuvien
                  tulisi olla laadukkaita. Laadukkaiden kuvien käyttö luo
                  kotisivuilla selkeän mielikuvan yrityksestä ja ihmisistä sen
                  takana.
                </Text>
              </div>
              <div className="mt-10">
                <SubTitle tag="h3">Hakukoneoptimointi</SubTitle>
                <Text textColor="text-white">
                  Teemme kotisivuista hakukoneoptimoidut – näin ollen tulet
                  erottumaan paremmin hakutuloksissa. Hakukoneoptimoiduissa
                  kotisivuissa on tärkeää verkkosivujen sisältö, mikä koostuu
                  uniikista sisällöstä ja harkituista avainsanoista.
                </Text>
                <Text textColor="text-white">
                  Eli niin sanotusti hakukoneoptimointi parantaa kotisivujen /
                  verkkosivustojen sijoittumista luonnollisissa hakutuloksissa
                  niihin käytettävillä avainsanoilla. Sen avulla tavoitat
                  entistä paremmin ihmiset, jotka ovat kiinnostuneita
                  tuotteistasi.
                </Text>
                <Text textColor="text-white">
                  Vaikka sivustosi olisi viimeisen päälle suunniteltu ja kaikki
                  näyttäisi pintapuolin täydelliseltä, ei siitä ole hyötyä jos
                  asiakkaat eivät löydä sivustoasi. Hakukoneoptimoinnin tulokset
                  eivät näy heti, vaan pidemmällä aikavälillä alat saamaan
                  tuloksia ja samalla tulet säästämään aikaa ja rahaa.
                </Text>
                <div className="mt-5">
                  <Text textColor="text-white">
                    Mitä hyötyä hakukoneoptimoinnista on?
                  </Text>
                  <BasicList>
                    <li>
                      <Text textColor="text-white">
                        Potentiaaliset asiakkaat löytävät tuotteesi ja palvelusi
                        paremmin
                      </Text>
                    </li>
                    <li>
                      <Text textColor="text-white">
                        Potentiaaliset asiakkaat löytävät tuotteesi ja palvelusi
                        paremmin
                      </Text>
                    </li>
                    <li>
                      <Text textColor="text-white">
                        Hakukonenäkyvyys paranee huomattavasti
                      </Text>
                    </li>
                    <li>
                      <Text textColor="text-white">
                        Käyttäjät klikkaavat mieluummin luonnollista kuin
                        maksettua hakutulosta
                      </Text>
                    </li>
                  </BasicList>
                  <Text textColor="text-white">
                    Hakukoneoptimointi pystyy mittaamaan tarkasti. Niiden avulla
                    pystymme selvittämään, kuinka hyvin kotisivusi löytyy
                    hakutuloksissa.
                  </Text>
                </div>
              </div>
            </div>
          </Container>
        </section>

        <section id="keeping-up">
          <DotSection dotPosition="top-right">
            <div className="grid lg:grid-cols-2">
              <div>
                <Title tag="h2">Kehityksen mukana pysyminen</Title>
                <Text>
                  Maailma kehittyy koko ajan ja sen mukana pysyminen on välillä
                  haastavaa. Kotisivujen on hyvä olla modernit ja
                  helppokäyttöiset.
                </Text>
              </div>
            </div>
          </DotSection>
        </section>

        <section>
          <Container>
            <Title tag="h2">Mitä kotisivuissa on huomioitava</Title>
            <Text>
              Kotisivuissa on osattava huomioida ja suunnitella tekstit, kuvat,
              sekä kotisivujen rakenne.
            </Text>
            <Text>
              Teksti on osattava suunnitella oikein, jotta se olisi
              SEO-ystävällistä ja se on osattava jaotella siten, että se on
              helppolukuista ja selkeää. Fonteista, kirjaimien koosta aina
              tekstien väreihin on hyvä kiinnittää huomiota ja panostaa niihin,
              jotta lopputulos olisi myös silmää miellyttävä.
            </Text>
            <Text>
              Kuvat mitkä laitetaan kotisivuille tulee myös suunnitella
              huolellisesti. Ovatko kuvat itse otettuja vai ostettu jostain
              kuvapankista, entä kaikki julkaisuoikeudet jos et omista kuvaa. Ne
              saattavat vaikuttaa pieniltä asioilta, mutta ne on otettava
              huomioon. Esimerkiksi liian suuren kuvan lisäämistä kotisivuille
              on vältettävä, sillä se tulee hidastamaan kotisivujen
              latausnopeutta, mikä taas vaikuttaa siihen jaksavatko ihmiset
              odottaa sivujen latautumista ja kuinka hyvät pisteet Google tulee
              antamaan sivuistasi. Kuva tulisi muokata ja jalostaa sen
              kokoiseksi mitä se vähintään vaatii soveltuakseen sille
              tarkoitettuun paikkaan kotisivuilla.
            </Text>

            <Text>
              Fonttehin, kirjainten kokoon sekä tekstien väreihin on
              kiinnitettävä huomiota. Esimerkiksi liian pieniä tai liian suuria
              kirjaimia on hankala lukea. Fontin värillä on myös suuri merkitys.
              Liian vaaleaa ei välttämättä näe kunnolla lukea, mutta myös ihan
              kokonaan mustan värin käyttämistä kannattaa välttää sillä
              pidempään luettaessa se saattaa alkaa väsyttää lukijan silmiä,
              joten oikean fontin värin löytäminen on tarkkaa. On myös osattava
              miettiä, mitkä värit sopivat keskenään ja mikä on kotisivujen
              teeman väri.
            </Text>
          </Container>
        </section>
      </section>
    </Layout>
  )
}
